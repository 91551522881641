import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { ContractStateModel } from "./pre-approved.state";
import { RenegotiationContractApiType } from "../shared/types/renegotiation.type";

export type IntelligentProposalDataType = {
    firstPaymentDate: number;
    downValue: number;
    installmentsValue: number;
    totalValue: number;
    totalInstallmentsValue: number;
    installmentsQuantity: number;
    paymentDates: number[];
};
  
export class SetIntelligentProposal {
    static readonly type = '[IntelligentProposal] Set Intelligent Proposal';
    constructor(public payload: IntelligentProposalDataType) {}
}

export interface IntelligentProposalStateModel {
    intelligentProposal: IntelligentProposalDataType;
}

@State<IntelligentProposalStateModel>({
    name: 'intelligentProposalState',
    defaults: {
        intelligentProposal: {
            firstPaymentDate: 0,
            downValue: 0,
            installmentsValue: 0,
            totalValue: 0,
            totalInstallmentsValue: 0,
            installmentsQuantity: 0,
            paymentDates: []
        }
    }
})
@Injectable()
export class IntelligentProposalState {

    @Selector()
    static getContract(state: ContractStateModel) {
      return state.contract;
    }

    @Selector()
    static getIntelligentProposal(state: IntelligentProposalStateModel) {
        return state.intelligentProposal;
    }

    @Action(SetIntelligentProposal)
    setIntelligentProposal(ctx: StateContext<IntelligentProposalStateModel>, action: SetIntelligentProposal) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            intelligentProposal: action.payload
        });
    }
}